import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from "styled-react-modal"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleSection } from "../../styledComponents/article"
import { FadingBackground } from "../../components/modalFadingBackground"
import {
  Section
} from "../../styledComponents/section"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import Modal from "../../components/modal"

const WebsiteDesign = ({ data, location, createCookie }) => {

  return (
    <Layout location={location}>
      <Seo
        title="Professional Web Design and Development Services"
        description="Our expert team offers custom web design and development solutions that are tailored to your unique needs. With years of experience and a dedication to quality, we'll help you build a stunning website that will help you stand out online. Contact us today to learn more!"
        image=""
        url="/services/website-design"
      />
      <HeroTextImageWrapper backgroundColor="var(--dark-blue-mid)" gridRowGap="0">
        <StaticImage
          loading="eager"
          alt=""
          backgroundcolor="#002F4A"
          layout="fullWidth"
          src={
            "../../images/website-design.png"
          }
          formats={["auto", "webp", "avif"]}
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1} fontColor="var(--white)">
            Professional website design services
          </HeroHeader>
          <HeroDescription>Your path to the perfect hosting plan starts here</HeroDescription>
        </HeroText>
      </HeroTextImageWrapper>
      <Article>
        <ArticleSection>
          <p>Are you looking for a professional team to design your website or application? Look no further! Our team of expert designers can create a stunning and user-friendly website or application that will help you grow your business and reach your goals.</p>

          <Section background="var(--mid-blue-mid)" type="pullout">
            <h2>Custom Built Websites That Fit Your Unique Needs</h2>
          </Section>


          <h2>Website Design</h2>
          <ul>
            <li>Custom website design: Our team of designers will create a website that perfectly reflects your brand and business objectives.</li>
            <li>User experience design: We'll ensure that your website is easy to use and navigate, so your users can find what they need quickly and efficiently.</li>
            <li>Responsive design: We'll create a website that looks great on any device, whether your users are browsing on a desktop, tablet, or mobile phone.</li>
            <li>Search engine optimization: We'll optimize your website so it ranks higher on search engines, helping you attract more traffic and potential customers.</li>
            <li>Content creation: We can help you create engaging and informative content for your website, including copywriting, images, and videos.</li>
          </ul>

          <h2>Application Design</h2>
          <ul>
            <li>User interface design: Our team of designers will create a visually appealing and user-friendly interface that your users will love.</li>
            <li>User experience design: We'll ensure that your application is easy to use and navigate, so your users can complete tasks quickly and efficiently.</li>
            <li>Responsive design: We'll create an application that looks great on any device, whether your users are using a desktop, tablet, or mobile phone.</li>
            <li>Branding: We'll help you create a consistent brand identity for your application, so it looks and feels like an extension of your business.</li>
            <li>Testing and debugging: We'll test your application thoroughly to ensure it's bug-free and runs smoothly.</li>
          </ul>

          <h2>Why Choose Us?</h2>
          <ul>
            <li>Experience: Our team of designers has years of experience creating websites and applications for businesses of all sizes and industries.</li>
            <li>Customization: We understand that every business is unique, which is why we'll create a custom design that perfectly fits your brand and business objectives.</li>
            <li>Communication: We'll work closely with you throughout the design process, ensuring that your needs and requirements are met every step of the way.</li>
            <li>Results-driven: Our goal is to create a design that helps you achieve your business goals, whether that's increased traffic, higher conversions, or better engagement.</li>
            <li>Affordable: We offer competitive pricing for our design services, so you can get a professional design without breaking the bank.</li>
          </ul>

          <p>Ready to Get Started?</p>
          <p>If you're ready to take your business to the next level with a stunning website or application design, <a href="/contact">contact us</a> today! We'll provide you with a free consultation and quote, so you can see how we can help you achieve your goals. Let's work together to create a design that you and your users will love!</p>
        </ArticleSection>
      </Article>

      <HeroTextImageWrapper
        backgroundColor="var(--orange-mid)"
      >
        <StaticImage
          layout="fullWidth"
          alt=""
          src={
            "../../images/contact-us-lets-talk.jpg"
          }
          formats={["auto", "webp", "avif"]}
          className="padding1"
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1}>Let's talk</HeroHeader>
          <HeroDescription>Conact us today to see how we can help your business online.</HeroDescription>
          <ModalProvider backgroundComponent={FadingBackground}>
            <Modal
              title="Contact Us"
              type="hero"
              serviceName="Website Design Optimisation"
              buttonLabel="Say hello 👋"
              buttonStyle="HeroButton"
              formExcerpt="Please fill in the following form and one of our team will get back to you within 24 hours."
            />
          </ModalProvider>
        </HeroText>
      </HeroTextImageWrapper>
    </Layout>
  )
}

export default WebsiteDesign
